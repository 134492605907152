import React, { ReactElement, ReactNode, Provider, ProviderProps } from 'react';
import { isContextProvider, isPortal, isValidElementType } from 'react-is';
import { useOutlet } from 'react-router-dom';
import type { PagePartsConfig } from '../helpers/page-parts';

/** `isPortal`'s typing just checks "ReactElement" */
type IsPortal = (value: any) => value is React.ReactPortal;
type ContextProviderElement = ReactElement<ProviderProps<any>, Provider<any>>;
type IsContextProvider = (value: any) => value is ContextProviderElement;

/**
 * This code may need to change if upstream changes the return value of useRoutes in react-router
 * @see https://github.com/ReactTraining/react-router/blob/v6.0.0-beta.4/packages/react-router/index.tsx#L604
 */
function updateRouteElement(
  el: ReactNode,
  update: (el: ReactNode) => ReactNode
): ReactNode {
  if (!React.isValidElement(el) || (isPortal as IsPortal)(el)) {
    return update(el);
  }
  if ((isContextProvider as IsContextProvider)(el)) {
    const children = el.props.children;
    return React.cloneElement(el, {
      children: updateRouteElement(children, update),
    });
  }
  // TODO: other types that might need to be transparently handled.
  return update(el);
}

interface OutletWithPartProps {
  part: keyof PagePartsConfig;
}
type UsePagePartResult = [outlet: React.ReactNode, hasPagePart?: boolean];
function usePagePartOutlet(part: keyof PagePartsConfig): UsePagePartResult {
  const outlet = useOutlet();
  if (!outlet) return [outlet];
  let hasPagePart = false;
  const node = updateRouteElement(outlet, (el) => {
    if (!React.isValidElement(el)) return;
    const elType = el.type;
    if (!isValidElementType(elType)) return;
    const PartComponent = elType[part];
    if (!isValidElementType(PartComponent)) return;
    hasPagePart = true;
    return <PartComponent key={el.key} {...el.props} />;
  });
  return [node, hasPagePart];
}
const PagePartOutlet: React.VFC<OutletWithPartProps> = (props) => {
  const { part } = props;
  const partOutlet = usePagePartOutlet(part)[0];
  return React.isValidElement(partOutlet) ? partOutlet : <>{partOutlet}</>;
};

// // Example of other uses for updateRouteElement
// function OutletWithProps<T>(props: T) {
//   const outlet = useOutlet();
//   if (!outlet) return outlet;
//   return updateRouteElement(outlet, (el) => {
//     if (!React.isValidElement(el)) return el;
//     return React.cloneElement(el, props);
//   });
// }

export { usePagePartOutlet };
export default PagePartOutlet;
