import React from 'react';
import { Option, SelectAntd } from '@owl-frontend/components';
import { useTenants } from '../../../tenants/TenantsContainerHooks';
import styles from '../../ClaimsAdminContainer.module.scss';
import ClaimsInsightsConfigDetailsContainer from './components/ClaimsAdminInsightsConfigDetailsContainer/ClaimsAdminInsightsConfigDetailsContainer';

const ClaimsInsightsConfigContainer: React.VFC = () => {
  const [{ tenants, loading }, useTenantsHandler] = useTenants();

  const [tenantId, setTenantId] = React.useState<string>();

  const tenantOptions: Option[] = React.useMemo(
    () =>
      tenants.map((tenant) => ({ label: tenant.name, value: tenant.tenantId })),
    [tenants]
  );

  React.useEffect(() => {
    useTenantsHandler.fetchTenants();
  }, [useTenantsHandler]);

  return (
    <div className={styles.content}>
      <SelectAntd
        placeholder="Select a Tenant"
        loading={loading}
        onChange={setTenantId}
        options={tenantOptions}
      />
      {tenantId && <ClaimsInsightsConfigDetailsContainer tenantId={tenantId} />}
    </div>
  );
};

export default ClaimsInsightsConfigContainer;
