import * as React from 'react';
import clsx from 'clsx';
import {
  Input as InputAntd,
  InputProps as InputAntdProps,
  InputRef,
} from 'antd';
import type { TextAreaProps as TextAreaAntdProps } from 'antd/lib/input/TextArea';
import formStyles from '../../shared/Form.module.scss';
import type { FieldElementProps } from '../../shared/interface';
import styles from './Input.module.scss';

// MARK: widget

interface Props {
  id?: string;
  label?: string | React.ReactNode;
  rows?: number;
  maxRows?: number;
  extra?: {
    label?: string;
    action?(): void;
  };
  trailer?: React.ReactNode;
  fieldClassName?: string;
  ref?: React.RefObject<InputRef>;
}

export type InputProps = InputAntdProps & FieldElementProps & Props;

export function useInputElement(
  props: InputProps
): React.ReactElement<{ id: string }> {
  const { className, name, error, onChange: onChangeProps, ...rest } = props;

  const onChange = React.useCallback(
    (e) => {
      onChangeProps?.(e);
    },
    [onChangeProps]
  );

  if (props.rows) {
    return (
      <InputAntd.TextArea
        name={name}
        className={clsx(
          'input',
          error && formStyles.isDanger,
          props.disabled && styles.disabled,
          className
        )}
        autoSize={{
          minRows: props.rows,
          maxRows: props.maxRows,
        }}
        // fixme: probably _fine_... (?)
        {...(rest as TextAreaAntdProps)}
        onChange={onChange}
      />
    );
  }

  return (
    <InputAntd
      name={name}
      className={clsx(
        'input',
        error && formStyles.isDanger,
        props.disabled && styles.disabled,
        className
      )}
      {...rest}
      onChange={onChange}
    />
  );
}

const Input: React.VFC<InputProps> = (props) => {
  const { fieldClassName, label, name, extra, trailer, ...rest } = props;

  const element = useInputElement({ name, ...rest });
  const htmlId = element.props.id;
  return (
    <div className={clsx(formStyles.field, fieldClassName)}>
      {(label || extra) && (
        <div className={clsx(formStyles.label, styles.labelContainer)}>
          <label htmlFor={htmlId}>{label}</label>
          {extra && (
            <span className={styles.extra} onClick={extra.action}>
              {extra.label}
            </span>
          )}
        </div>
      )}
      <div className={formStyles.control}>{element}</div>
      {trailer}
    </div>
  );
};

export default Input;
