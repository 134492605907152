import { createLogger } from '@owl-lib/logger';
import { __DEV__ } from '../constants';
import {
  composeCaseReducer,
  SliceActionType,
  iConcat,
  iMerge,
} from '../helpers';
import { isEmptyExtra, SliceExtra } from './extra';
import type { SliceCases, SliceCase } from './interface';
import type { ConfigurableSlice } from './slice';

const logger = createLogger('redux/lib/slice/merge');

export default function mergeSlice<
  State extends { [k: string]: any },
  Name extends string,
  AActions,
  BActions,
  BState extends State = State
>(
  a: ConfigurableSlice<State, Name, AActions>,
  b: ConfigurableSlice<BState, Name, BActions>
): ConfigurableSlice<
  State extends BState ? State : BState,
  Name,
  AActions & BActions
> {
  type S = State extends BState ? State : BState;
  type R = ConfigurableSlice<S, Name, AActions & BActions>;
  if (__DEV__) {
    // Check merge compatability
    if (a.name !== b.name) {
      throw new Error(
        `Cannot merge slices with different names ('${a.name}' !== '${b.name}')`
      );
    }
    for (const stateKey of new Set([
      ...Object.keys(a.initialState),
      ...Object.keys(b.initialState),
    ])) {
      const aState = a.initialState[stateKey];
      const bState = b.initialState[stateKey];
      if (aState && bState && aState !== bState) {
        throw new Error(`Initial state mismatch ${stateKey}`);
      }
    }
    for (const actionKey of new Set([
      ...Object.keys(a.actions),
      ...Object.keys(b.actions),
    ])) {
      const aAction = a.actions[actionKey];
      const bAction = b.actions[actionKey];
      if (aAction && bAction && aAction !== bAction) {
        const aCasePrepare = (a.cases as AnySliceCases)[actionKey]?.prepare;
        const bCasePrepare = (b.cases as AnySliceCases)[actionKey]?.prepare;
        if (aCasePrepare !== bCasePrepare) {
          throw new Error(`Action creator mismatch ${actionKey}`);
        } else {
          logger.warn(
            `Duplicate action creator for ${a.name}/${actionKey}. Consider predeclaring the action with "addActions"`
          );
        }
      }
    }
  }
  type AnySliceCases = { [K in string]: SliceCase<any, any, any> };
  let cases = a.cases as AnySliceCases as SliceCases<S, any, Name>;
  if ((a as typeof a & typeof b).cases !== b.cases) {
    cases = {};
    for (const actionKey of new Set([
      ...Object.keys(a.cases),
      ...Object.keys(b.cases),
    ])) {
      type ActionType = SliceActionType<Name, string>;
      const aCase: SliceCase<S, any, ActionType> = a.cases[actionKey];
      const bCase: SliceCase<S, any, ActionType> = b.cases[actionKey];
      if (!bCase || aCase === bCase) {
        cases[actionKey] = aCase;
      } else if (!aCase) {
        cases[actionKey] = bCase;
      } else {
        if (__DEV__ && aCase.prepare !== bCase.prepare) {
          throw new Error(`Cannot merge: ${actionKey} prepare mismatch`);
        }
        const mergedCase: SliceCase<S, any> = {
          prepare: aCase.prepare,
        };
        if (!bCase.reducer || aCase.reducer === bCase.reducer) {
          if (aCase.reducer) mergedCase.reducer = aCase.reducer;
        } else if (!aCase.reducer) {
          mergedCase.reducer = bCase.reducer;
        } else if (__DEV__) {
          throw new Error(`Cannot merge: ${actionKey} reducer mismatch`);
        }
        if (!bCase.saga || aCase.saga === bCase.saga) {
          if (aCase.saga) {
            mergedCase.saga = aCase.saga;
            mergedCase.scheduler = aCase.scheduler;
          }
        } else if (!aCase.saga) {
          mergedCase.saga = bCase.saga;
          mergedCase.scheduler = bCase.scheduler;
        } else if (__DEV__) {
          throw new Error(`Cannot merge: ${actionKey} saga mismatch`);
        }
        cases[actionKey] = mergedCase;
      }
    }
  }
  const aExtra = a.extra;
  const bExtra = b.extra;
  const extra: SliceExtra<S> = (
    isEmptyExtra(bExtra)
      ? aExtra
      : isEmptyExtra(aExtra)
      ? bExtra
      : {
          actionsMap: iMerge(aExtra.actionsMap, bExtra.actionsMap),
          actionMatchers: iConcat(aExtra.actionMatchers, bExtra.actionMatchers),
          defaultCaseReducer: composeCaseReducer(
            aExtra.defaultCaseReducer,
            bExtra.defaultCaseReducer as typeof aExtra.defaultCaseReducer
          ),
          sagas: iConcat(aExtra.sagas, bExtra.sagas),
        }
  ) as typeof a['extra'] & typeof b['extra'] & R['extra'];
  type MergedActions = typeof a['actions'] & typeof b['actions'] & R['actions'];
  return a.clone<AActions & BActions, S>({
    initialState: iMerge(a.initialState, b.initialState) as S,
    actions: iMerge(a.actions, b.actions) as MergedActions,
    cases: cases as typeof cases & SliceCases<S, AActions & BActions, Name>,
    extra,
  });
}
