import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { SessionContext } from '../../context/AppContainer/AppContainer';
import {
  TenantDetailsPage,
  DossiersUploadPage,
  TenantsPage,
  TasksPage,
  UsersPage,
  FowlTopologiesPage,
  FulfillmentCapacityPage,
  FeedbackPage,
  FeedbackAdminPage,
  ClaimsPage,
  TenantUsersPage,
  TenantUserDetailsPage,
  ClaimsContainerPage,
  ClaimDetailsPage,
  ClaimsInsightsPage,
  ClaimsTenantList,
  ClaimsChunksPage,
  ClaimsInsightsConfigPage,
  ClaimsChunksDetailsPage,
  ClaimsChunksDossierPage,
  ClaimsChunksDocumentsPage,
  ClaimsChunksClaimantPage,
  ClaimsHistoryPage,
  ClaimsAdminPage,
  ClaimantInfoPage,
} from '../../pages';
import ClaimsAdminComponent from '../../pages/claims-admin/components/ClaimsAdminComponent/ClaimsAdminComponent';
import ClaimsAdminDocComponent from '../../pages/claims-admin/components/ClaimsAdminDocComponent/ClaimsAdminDocComponent';
import ClaimsAdminDocsComponent from '../../pages/claims-admin/components/ClaimsAdminDocsComponent/ClaimsAdminDocsComponent';
import ClaimsAdminInsightsConfigContainer from '../../pages/claims-admin/components/ClaimsAdminInsightsConfigComponent/ClaimsAdminInsightsConfigContainer';
import ClaimsAdminMLConfigContainer from '../../pages/claims-admin/components/ClaimsAdminMLConfigComponent/ClaimsAdminMLConfigComponent';
import MainContainer from '../MainContainer/MainContainer';
import { RouteDefs } from './routes';
import DefaultRedirect from './DefaultRedirect';

// We are using react-router v6. See the documentation:
// https://github.com/remix-run/react-router/blob/dev/docs/api-reference.md
// and differences here:
// https://github.com/remix-run/react-router/blob/dev/docs/advanced-guides/migrating-5-to-6.md
// See https://github.com/remix-run/react-router/releases/tag/v6.0.0-beta.4 for some info on routes

const Routing: React.VFC = () => {
  const { current } = useContext(SessionContext);
  return (
    <Routes>
      <Route path={RouteDefs.ClaimantInfo} element={<ClaimantInfoPage />} />
      <Route path={RouteDefs.Main} element={<MainContainer />}>
        <Route index element={<DefaultRedirect path={'/'} />} />
        <Route path={RouteDefs.Tenants} element={<TenantsPage />} />
        <Route path={RouteDefs.TenantDetails} element={<TenantDetailsPage />} />
        <Route
          path={RouteDefs.TenantUserDetails}
          element={<TenantUserDetailsPage />}
        />
        <Route
          path={RouteDefs.DossiersUpload}
          element={<DossiersUploadPage />}
        />
        <Route path={RouteDefs.Tasks} element={<TasksPage />} />
        <Route path={RouteDefs.Users} element={<UsersPage />} />
        <Route
          path={RouteDefs.FowlTopologies}
          element={<FowlTopologiesPage />}
        />
        <Route
          path={RouteDefs.FulfillmentCapacity}
          element={<FulfillmentCapacityPage />}
        />
        {current?.permission?.['admin'] ? (
          <Route path={RouteDefs.Feedback} element={<FeedbackAdminPage />} />
        ) : (
          <Route path={RouteDefs.Feedback} element={<FeedbackPage />} />
        )}
        <Route path={RouteDefs.TenantUsers} element={<TenantUsersPage />} />
        <Route path={RouteDefs.ClaimsAdmin} element={<ClaimsAdminPage />}>
          <Route index element={<ClaimsAdminComponent />} />
          <Route
            path={RouteDefs.ClaimsAdminErrorDocs}
            element={<ClaimsAdminDocsComponent />}
          />
          <Route
            path={RouteDefs.ClaimsAdminErrorDocsTenant}
            element={<ClaimsAdminDocsComponent />}
          />
          <Route
            path={RouteDefs.ClaimsAdminErrorDocsTenantDoc}
            element={<ClaimsAdminDocComponent />}
          />
          <Route
            path={RouteDefs.ClaimsAdminInsightsConfig}
            element={<ClaimsAdminInsightsConfigContainer />}
          />
          <Route
            path={RouteDefs.ClaimsAdminMLConfig}
            element={<ClaimsAdminMLConfigContainer />}
          />
        </Route>
      </Route>
      <Route path={RouteDefs.Claims} element={<ClaimsContainerPage />}>
        <Route
          index
          element={<DefaultRedirect path={RouteDefs.ClaimsHome} />}
        />
        <Route path={RouteDefs.ClaimsHome} element={<ClaimsTenantList />} />
        <Route path={RouteDefs.ClaimsList} element={<ClaimsPage />} />
        <Route path={RouteDefs.ClaimDetails} element={<ClaimDetailsPage />} />
        <Route
          path={RouteDefs.ClaimsAnalytics}
          element={<ClaimsInsightsPage />}
        />
        <Route
          path={RouteDefs.ClaimsAnalyticsTenant}
          element={<ClaimsInsightsPage />}
        />
        <Route path={RouteDefs.ClaimChunks} element={<ClaimsChunksPage />} />
        <Route
          path={RouteDefs.ClaimChunksTenant}
          element={<ClaimsChunksClaimantPage />}
        />
        <Route
          path={RouteDefs.ClaimChunksDossier}
          element={<ClaimsChunksDossierPage />}
        />
        <Route
          path={RouteDefs.ClaimChunksDocument}
          element={<ClaimsChunksDocumentsPage />}
        />
        <Route
          path={RouteDefs.ClaimChunkDetails}
          element={<ClaimsChunksDetailsPage />}
        />
        <Route
          path={RouteDefs.ClaimsInsightsConfig}
          element={<ClaimsInsightsConfigPage />}
        />
        <Route path={RouteDefs.ClaimsHistory} element={<ClaimsHistoryPage />} />
      </Route>
    </Routes>
  );
};

export default Routing;
