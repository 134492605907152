// eslint-disable-next-line node/no-extraneous-import
import type { ItemType } from '@owl-frontend/components/src/shared/type-helpers';
import type {
  V3Base,
  LongTermDisability,
  WorkersCompOrig,
  Base,
} from '@owl-lib/form-schema-config';
import type { DossierMetadata } from '@owl-lib/form-schema-config/src/lib/v3-base-types';

export enum ClaimantDecisionDetailAcceptedReason {
  QuestionsEligibility = 'questions-eligibility',
  ConfirmsEligibility = 'confirms-eligibility',
  Interesting = 'interesting',
}

export enum ClaimantDecisionDetailDismissedReason {
  InsufficientEvidence = 'insufficient-evidence',
  NotFeasible = 'not-feasible',
}

export enum WCClaimantDecisionDetailDismissedReason {
  AlreadyKnown = 'already-known',
  EvidenceSupportsLoss = 'insufficient',
  CannotImpactClaim = 'infeasible',
}

export enum InfeasibleReasons {
  AlreadySettled = 'already-settled',
  CourtOrderedPayment = 'court-ordered-payment',
  InProcess = 'in-process',
  OpenMedical = 'open-medical',
  PermanencyAward = 'permanency-award',
  SurvivorBenefit = 'survivor-benefit',
  Other = 'other',
}

// Example: 'RavenClearValidationSourceHandlerQueueLocal821497916' | 'RavenLtdInvestigationSourceHandlerQueueDev885d7cf9'
export type TopologyTarget = string | undefined;

export type FILTER_KEYS = 'clientDossierId' | 'name' | 'owner';

export interface DossierBase {
  dossierId: string;
  dossierType?: 'long-term-disability' | 'workers-comp';
  updatedAt: string;
  createdAt: string;
  updatedByUsername: string;
  conclusionFinalized: boolean;
  conclusion: 'accepted' | 'dismissed' | 'undecided';
  conclusionDetails?: {
    reason?:
      | ClaimantDecisionDetailAcceptedReason
      | ClaimantDecisionDetailDismissedReason
      | WCClaimantDecisionDetailDismissedReason
      | InfeasibleReasons;
    comment?: string;
  };
  closeDate?: string;
  owner?: string;
  conclusionSetByName?: string;
  conclusionSetByUsername?: string;
  conclusionSetAt?: number;
  userConclusionKey?: string;
  updatedKey?: string;
  updatedByName?: string;
  status: 'in-progress' | 'ready' | 'done' | 'closed';
  deletedAt?: string;
  numFindingsTotal: number;
  clientDossierId: string;
  investigationId?: string;
  name?: V3Base['names'][0];
  metadata?: DossierMetadata;
  data: V3Base;
}

export interface DossierLtd extends DossierBase {
  dossierType?: 'long-term-disability';
  data: LongTermDisability;
}

export interface DossierWorkersComp extends DossierBase {
  dossierType: 'workers-comp';
  data: WorkersCompOrig;
}

export type Dossier = DossierLtd | DossierWorkersComp;
export type ListDossier = {
  dossierId: string;
  clientDossierId: string;
  numFindingsTotal: number;
  name: ItemType<V3Base['names']>;
  updatedAt: string;
  status: Dossier['status'];
  conclusion: Dossier['conclusion'];
  owner?: string;
};

export type ImportDossier = V3Base;

export interface SaveDossiersBatchResponse {
  numCreated: number;
}

export interface ReinvestigationsResponse {
  investigationId: string;
  batchProcessLogId: string;
  dossiersCount: number;
}

export interface GetPresignedUploadResponse {
  url: string;
  contentType: string;
  metadata: Record<string, string>;
  file: File;
}

export interface ReservationResponse {
  ptSortKey: string;
  recordId: string;
  expires: number;
  expiryBufferSeconds?: number;
  data: { [key: string]: any };
  groupId: string;
  priority: number;
  peekedById: string | null;
  insertedAt: string;
}

export interface AssignmentDetails {
  toUserId: string;
  toUserName: string;
  fromUserId: string;
  fromUserName: string;
  assignedAt: string;
}

export interface SnoozeDetails {
  snoozedById?: string;
  snoozeExpires?: number;
  snoozeUntil?: number;
  snoozeDetails?: {
    snoozedById: string;
    snoozedAt: number;
    snoozedByUsername: string;
  };
}

export type Name = ItemType<Base['names']>;

export type Address = ItemType<Base['addresses']>;

export type DossierRecordDataPartial = Pick<
  Base,
  'phoneNumbers' | 'addresses' | 'names' | 'links' | 'birthDate'
>;

export type DossierFilterParams = {
  clientDossierId?: string;
  name?: string;
  owner?: string;
  conclusion?: Dossier['conclusion'];
  isOwner?: boolean;
  isReviewedByUser?: boolean;
  priorityType?: string;
};
