import React, { useEffect, useMemo, useRef } from 'react';
import type { NoInfer } from '@owl-lib/type-util';

export function usePrev<T>(value: T): T {
  const ref = useRef(value);
  const prev = ref.current;
  ref.current = value;
  return prev;
}

/**
 * `useMemoWithPrevResult` will recompute the memoized value when one of the `deps`
 * has changed. Additionally, the previously computed value will be passed to
 * the computation function.
 *
 * Usage note: if calling `useMemo` with a referentially stable function, also
 * give it as the input in the second argument.
 *
 * @see useMemo
 */
export function useMemoWithPrevResult<T>(
  factory: (prev?: NoInfer<T>) => T,
  deps: React.DependencyList
): T {
  const ref = useRef<T>();
  // eslint-disable-next-line react-hooks2/exhaustive-deps
  const result = useMemo(() => factory(ref.current), deps);
  useEffect(() => {
    ref.current = result;
  }, [result]);
  return result;
}
/**
 * `useMemoWithPrevDeps` will recompute the memoized value when one of the
 * `deps` has changed. Additionally, the previous dependencies will be passed
 * to the computation function as arguments.
 *
 * Usage note: if calling `useMemo` with a referentially stable function, also
 * give it as the input in the second argument.
 *
 * @see useMemo
 */
export function useMemoWithPrevDeps<
  T,
  D = unknown,
  E = unknown,
  F = unknown,
  G = unknown
>(
  factory: (d?: D, e?: E, f?: F, g?: G) => T,
  deps:
    | readonly [D, E, F, G, ...unknown[]]
    | readonly [D, E, F]
    | readonly [D, E]
    | readonly [D]
    | readonly []
): T {
  const ref = useRef<React.DependencyList>();
  const result = useMemo(
    () => factory(...(ref.current ?? [])),
    deps // eslint-disable-line react-hooks2/exhaustive-deps
  );
  useEffect(() => {
    ref.current = deps;
  }, [deps]);
  return result;
}
/**
 * Accepts a function that contains imperative, possibly effectful code.
 * Additionally, the previous value of the `deps` will be passed into the `effect` function.
 *
 * @param effect Imperative function that can return a cleanup function
 * @param deps If present, effect will only activate if the values in the list change.
 *
 * @see useEffect
 */
export function useEffectWithPrev<
  T,
  U = unknown,
  V = unknown,
  W = unknown,
  X = unknown,
  Y = unknown,
  Z = unknown
>(
  effect: // prettier-ignore
  (a?: T, b?: U, c?: V, d?: W, e?: X, f?: Y, g?: Z) => ReturnType<React.EffectCallback>,
  deps:
    | readonly [T, U, V, W, X, Y, Z, ...unknown[]]
    | readonly [T, U, V, W, X, Y]
    | readonly [T, U, V, W, X]
    | readonly [T, U, V, W]
    | readonly [T, U, V]
    | readonly [T, U]
    | readonly [T]
): void {
  const ref = useRef<React.DependencyList>();
  return useEffect(() => {
    const prev = ref.current;
    ref.current = deps;
    return effect(...(prev ?? []));
  }, deps); // eslint-disable-line react-hooks2/exhaustive-deps
}
