import React from 'react';
import { SearchOutlined, MacCommandOutlined } from '@ant-design/icons';
import { Layout, Input, Typography, Space } from 'antd';
import { Button, ButtonProps } from '@owl-frontend/components';
import styles from './ClaimsAdminHeaderComponent.module.scss';

interface Props {
  title: string;
  buttonProps?: ButtonProps[];
  searchProps?: {
    inputPlaceholder: string;
    searchVal?: string;
    onSearch(e: React.ChangeEvent<HTMLInputElement>): void;
  };
}

const ClaimsAdminHeader: React.VFC<Props> = ({
  title,
  buttonProps = [],
  searchProps,
}) => {
  return (
    <Layout.Header className={styles.header}>
      <Typography.Title level={3}>{title}</Typography.Title>
      {searchProps && (
        <Input
          placeholder={searchProps.inputPlaceholder}
          prefix={<SearchOutlined />}
          addonAfter={
            <div className={styles.letterContainer}>
              <MacCommandOutlined className={styles.icon} />
              <Space className={styles.letter}>K</Space>
            </div>
          }
          className={styles.searchInput}
          onChange={searchProps.onSearch}
          value={searchProps.searchVal}
        />
      )}
      <div className={styles.buttonContainer}>
        {buttonProps.map((b, index) => (
          <Button
            key={`claims-header-btn--${index}`}
            disabled={b.disabled}
            href={b.href}
            type={b.type ?? 'default'}
            icon={b.icon}
            onClick={b.onClick}
          >
            {b.label}
          </Button>
        ))}
      </div>
    </Layout.Header>
  );
};

export default ClaimsAdminHeader;
