import React from 'react';
import { Descriptions } from 'antd';
import type { ClaimsMLConfig } from '@owl-frontend/api-client/interface';
import {
  Button,
  Dialog,
  Form,
  toast,
  useEffectWithPrev,
} from '@owl-frontend/components';
import { Locale } from '../../../../context/AppContainer/AppContainer';
import useMlConfig from '../../hooks/UseMLConfigsHook';
import styles from './ClaimsAdminMLConfigComponent.module.scss';

interface Props {
  tenantId: string;
}

interface FormProps {
  onClose(): void;
  visible?: boolean;
  updateStatus?: string;
}

const MLDetailsForm: Form.MFC<FormProps, ClaimsMLConfig> = ({
  handleSubmit,
  onClose,
  visible,
  updateStatus,
  form: rffForm,
  pristine,
}) => {
  const { messages } = React.useContext(Locale);
  React.useEffect(() => {
    if (!visible) {
      rffForm.restart();
    }
  }, [visible, rffForm]);

  return (
    <Dialog
      aria-label="ML Details"
      title={messages['claimsAdmin.mlConfig.updateConfigDialog.title']}
      size={'medium'}
      open={visible}
      onClose={onClose}
      destroyOnClose
      footer={{
        onConfirmOpts: {
          label: messages['shared.save'],
          onClick: handleSubmit,
          loading: updateStatus === 'pending',
          disabled: pristine,
        },
        onCancelOpts: {
          label: messages['shared.cancel'],
          onClick: onClose,
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <Form.TextAreaManaged
          name="searchPrompt"
          label={messages['claims.config.searchPrompt']}
          rows={5}
          validate={Form.Validators.required()}
        />
        <Form.TextAreaManaged
          name="queryEmbeddingType"
          label={messages['claims.config.queryEmbeddingType']}
          rows={1}
          validate={Form.Validators.required()}
        />
        <Form.TextAreaManaged
          name="summaryPrompt"
          label={messages['claims.config.summaryPrompt']}
          rows={5}
          validate={Form.Validators.required()}
        />
        <Form.TextAreaManaged
          name="insightsPrompt"
          label={messages['claims.config.insightsPrompt']}
          rows={5}
          validate={Form.Validators.required()}
        />
        <Form.TextAreaManaged
          name="completionModel"
          label={messages['claims.config.completionModel']}
          rows={1}
          validate={Form.Validators.required()}
        />
        <Form.TextAreaManaged
          name="maxChunkSize"
          label={messages['claims.config.maxChunkSize']}
          rows={1}
          validate={Form.Validators.combineValidators([
            Form.Validators.required(),
            Form.Validators.isInteger({
              errorMessage: 'Value should be an integer',
            }),
          ])}
        />
        <Form.TextAreaManaged
          name="topK"
          label={messages['claims.config.topK']}
          rows={1}
          validate={Form.Validators.combineValidators([
            Form.Validators.required(),
            Form.Validators.isInteger({
              errorMessage: 'Value should be an integer',
            }),
          ])}
        />
        <Form.TextAreaManaged
          name="formatChunkPrompt"
          label={messages['claims.config.formatChunkPrompt']}
          rows={1}
          validate={Form.Validators.required()}
        />
        <Form.TextAreaManaged
          name="embeddingModel"
          label={messages['claims.config.embeddingModel']}
          rows={1}
          validate={Form.Validators.required()}
        />
      </form>
    </Dialog>
  );
};

const MLDetailsFormManaged = Form.manage(MLDetailsForm);

const MLConfigDetailsContainer: React.VFC<Props> = ({ tenantId }) => {
  const { messages } = React.useContext(Locale);
  const [{ mlConfig, updateStatus }, MLConfigHookHandler] =
    useMlConfig(tenantId);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending' || !tenantId) {
        return;
      }

      if (updateStatus === 'fulfilled') {
        toast({
          key: 'SAVE_ML_CONFIG_SUCCESS',
          type: 'success',
          message: messages['claimsAdmin.mlConfig.updateConfigDialog.success'],
        });
        if (tenantId) {
          MLConfigHookHandler.mlConfigFetch();
        }
      }

      if (updateStatus === 'rejected') {
        toast({
          key: 'SAVE_ML_CONFIG_FAIL',
          type: 'error',
          message: messages['claimsAdmin.mlConfig.updateConfigDialog.error'],
        });
      }
    },
    [updateStatus, tenantId, MLConfigHookHandler, messages]
  );

  const handleSave = React.useCallback(
    (data, form) => {
      const { dirtyFields } = form.getState();
      const filteredValues = Object.keys(data)
        .filter((key) => dirtyFields[key])
        .reduce((obj, key) => {
          obj[key] = data[key];
          return obj;
        }, {} as Record<string, any>);
      MLConfigHookHandler.updateMLConfig(filteredValues);
      setIsEdit(false);
    },
    [MLConfigHookHandler]
  );

  React.useEffect(() => {
    MLConfigHookHandler.mlConfigFetch();
  }, [tenantId, MLConfigHookHandler]);

  return (
    <>
      {mlConfig && Object.keys(mlConfig).length > 0 && (
        <div className={styles.mlConfigDetailsContainer}>
          <Button className={styles.editButton} onClick={() => setIsEdit(true)}>
            {messages['shared.edit']}
          </Button>
          <Descriptions bordered column={1}>
            {Object.keys(mlConfig).map((entry, key) => (
              <Descriptions.Item
                label={messages[`claims.config.${entry}`]}
                key={key}
              >
                {mlConfig[entry]
                  .toString()
                  .split('\n')
                  .map((line: string, index: number) => (
                    <div key={index}>{line}</div>
                  ))}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </div>
      )}
      <MLDetailsFormManaged
        onClose={() => setIsEdit(false)}
        visible={isEdit}
        initialValues={mlConfig}
        onSubmit={handleSave}
        updateStatus={updateStatus}
        subscription={{ dirtyFields: true }}
      />
    </>
  );
};

export default MLConfigDetailsContainer;
