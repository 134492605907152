import React from 'react';
import formStyles from '../shared/Form.module.scss';

interface Props {
  errorMessage: string;
}

const ErrorDisplayComponent: React.VFC<Props> = ({ errorMessage }) => {
  return <span className={formStyles.isDanger}>{errorMessage}</span>;
};

export default ErrorDisplayComponent;
