import React from 'react';
import { Image } from 'antd';
import type { MatchAnswer } from '@owl-frontend/api-client/interface';
import PDFDocumentViewer from './PDFDocumentViewerComponent/PDFDocumentViewerComponent';
import TextDocumentViewer from './TextDocumentViewerComponent/TextDocumentViewerComponent';

export interface Props {
  pdfFile?: File;
  content?: { content: string; fileType: string };
  docDisplayPage?: number;
  docNumPages?: number;
  documentUrl?: string;
  documentMatches?: MatchAnswer[];
  initialPage?: number;
  pageNumber?: number;
  highlight?: [number, number, number, number];
  onChangePage?(num: number): void;
}

export enum FileType {
  PdfApplication = 'application/pdf',
  Pdf = 'pdf',
  Txt = 'txt',
  Jpeg = 'jpeg',
}

const ClaimsAssistDocumentComponent: React.VFC<Props> = ({
  pdfFile,
  content,
  docDisplayPage,
  docNumPages,
  documentMatches = [],
  documentUrl,
  initialPage = 1,
  pageNumber,
  onChangePage,
  highlight,
}) => {
  if (
    pdfFile ||
    content?.fileType === FileType.Pdf ||
    content?.fileType === FileType.PdfApplication
  ) {
    return (
      <PDFDocumentViewer
        file={pdfFile ?? content?.content}
        documentMatches={documentMatches}
        docDisplayPage={docDisplayPage}
        docNumPages={docNumPages}
        documentUrl={documentUrl}
        initialPage={initialPage}
        highlight={highlight}
        pageNumber={pageNumber}
        onChangePage={onChangePage}
      />
    );
  }

  if (content?.fileType === FileType.Txt) {
    return (
      <TextDocumentViewer
        content={content.content}
        documentMatches={documentMatches}
      />
    );
  }

  if (content?.fileType === FileType.Jpeg) {
    return <Image width={500} src={content.content} />;
  }

  return null;
};

export default ClaimsAssistDocumentComponent;
