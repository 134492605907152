import { fowlApiClient } from '@owl-frontend/api-client';
import type { TopologyState } from '@owl-frontend/api-client/src/fowl/interface';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import fowlSlice, { TopologiesState } from './interface';

function updateStateForArn(
  state: TopologiesState,
  arn: string,
  topologyState?: TopologyState
) {
  const idx = state.topologies.findIndex((t) => t.arn === arn);
  if (idx >= 0) {
    state.topologies[idx].state = topologyState;
  }
}

const slice = fowlSlice
  .addAsyncSagas({
    listTopologies: invoke(fowlApiClient.topologies.list),
    getPauseState: invoke(fowlApiClient.topologies.getPauseState),
    updatePauseState: invoke(fowlApiClient.topologies.updatePauseState),
    getTaskQueuesPausedState: invoke(
      fowlApiClient.topologies.getTaskQueuesPausedState
    ),
    getTaskQueuesMappingState: invoke(
      fowlApiClient.topologies.getTaskQueuesMappingState
    ),
    updateTaskQueuePauseState: invoke(
      fowlApiClient.topologies.updateTaskQueuePauseState
    ),
  })
  .addReducers({
    'listTopologies/fulfilled': (state, action) => {
      state.defaults = Object.fromEntries(action.payload.defaults);
      state.topologies = action.payload.topologies;
    },
    'getPauseState/pending': (state, action) => {
      updateStateForArn(state, action.meta.arg.arn, undefined);
    },
    'getPauseState/fulfilled': (state, action) => {
      updateStateForArn(state, action.meta.arg.arn, action.payload);
    },
    'updatePauseState/pending': (state, action) => {
      updateStateForArn(state, action.meta.arg.arn, undefined);
    },
    'updatePauseState/fulfilled': (state, action) => {
      updateStateForArn(state, action.meta.arg.arn, action.payload);
    },
    'getTaskQueuesPausedState/fulfilled': (state, action) => {
      state.pausedTopologyTaskQueues = action.payload.paused;
    },
    'getTaskQueuesMappingState/fulfilled': (state, action) => {
      state.topologyTaskQueues = action.payload.taskQueues.reduce(
        (acc, i) => ({
          ...acc,
          [i.eventSourceArn]: i,
        }),
        {}
      );
    },
    'updateTaskQueuePauseState/fulfilled': (state, action) => {
      const arn = action.payload.arn;
      const paused = action.meta.arg.paused;
      state.topologyTaskQueues[arn] = action.payload;

      if (paused) {
        if (state.pausedTopologyTaskQueues.indexOf(arn) === -1) {
          state.pausedTopologyTaskQueues = [
            ...state.pausedTopologyTaskQueues,
            arn,
          ];
        }
      } else {
        state.pausedTopologyTaskQueues = state.pausedTopologyTaskQueues.filter(
          (i) => i !== arn
        );
      }
    },
  })
  .addReducers({
    clearItems: (state) => {
      state.topologies = [];
      state.topologyTaskQueues = {};
      state.pausedTopologyTaskQueues = [];
    },
  });
export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
