import * as React from 'react';
import clsx from 'clsx';
import { Select as SelectAntd, SelectProps } from 'antd';
import type { FieldWrapperProps } from '../../../shared/interface';
import styles from '../Select.module.scss';

export interface Props extends SelectProps, FieldWrapperProps {
  menuTitle?: string;
}

type UseMultiSelectElement = (props: Props) => [React.ReactElement];
export const useMultiSelectElement: UseMultiSelectElement = (selectProps) => {
  const { menuTitle, ...rest } = selectProps;

  const dropdownRender = (menu) => (
    <>
      {menuTitle && <div className={styles.menuHeader}>{menuTitle}</div>}
      {menu}
    </>
  );

  const el = (
    <SelectAntd {...rest} mode="multiple" dropdownRender={dropdownRender} />
  );
  return [el];
};

const MultiSelectAntd: React.VFC<Props> = (props) => {
  const { label, fieldClassName, trailer, info } = props;

  const [element] = useMultiSelectElement(props);

  return (
    <div className={clsx('field', styles.selectContainer, fieldClassName)}>
      {label && <label className="label">{label}</label>}
      <div className="control">{element}</div>
      {trailer}
      {info && <div className={styles.info}>{info}</div>}
    </div>
  );
};

export default MultiSelectAntd;
