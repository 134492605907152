import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Menu, MenuItemProps } from '@owl-frontend/components';
import { SessionContext } from '../../context/AppContainer/AppContainer';
import { actions as sessionSliceActions } from '../../data/session/logic';
import type { StoreState } from '../../data/store';
import { useTaskCount } from '../../pages/workers/hooks/useTaskCount';
import PagePartOutlet from '../PagePartOutlet';
import { RouteDefs } from '../Routing/routes';
import styles from './MainContainer.module.scss';

const MainContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const messages = useSelector((s: StoreState) => s['localization'].messages);
  const session = useContext(SessionContext);
  const roles = session.current?.roles;
  const [totalTimeOnPlatform, setTotalTimeOnPlatform] = useState<string | null>(
    null
  );
  const taskCount = useTaskCount();
  const currentPath = location.pathname;
  const currentSearch = location.search;
  const isOnTasksPage = currentPath === RouteDefs.Tasks && currentSearch === '';

  useEffect(() => {
    if (isOnTasksPage) {
      setTotalTimeOnPlatform(taskCount.totalTimeOnPlatform);
    } else {
      setTotalTimeOnPlatform(null);
    }
  }, [isOnTasksPage, taskCount.totalTimeOnPlatform]);

  const onMenuItemClick = React.useCallback(
    (menuKey: string) => {
      if (menuKey === 'logout') {
        dispatch(sessionSliceActions.logout());
        return;
      }

      navigate(menuKey);
    },
    [navigate]
  );

  const menuItems = React.useMemo(() => {
    const items: MenuItemProps[] = [];
    const isAdmin = roles && roles.indexOf('admin') > -1;
    const isTenantUserManagement =
      roles && roles.indexOf('tenant-user-management') > -1;
    if (isAdmin) {
      items.push({
        key: RouteDefs.Tenants,
        label: messages['tenants.title'],
        'data-testid': 'tenantsPage-btn',
      });
      items.push({
        key: RouteDefs.Users,
        label: messages['users.title'],
        'data-testid': 'usersPage-btn',
      });
      items.push({
        key: RouteDefs.FowlTopologies,
        label: messages['fowlTopologies.title'],
        'data-testid': 'topologiesPage-btn',
      });
      items.push({
        key: RouteDefs.FulfillmentCapacity,
        label: messages['fulfillmentCapacity.title'],
        'data-testid': 'fulfillmentPage-btn',
      });
      items.push({
        key: RouteDefs.Claims,
        label: messages['claims.title'],
        'data-testid': 'claimsPage-btn',
      });
      items.push({
        key: RouteDefs.ClaimsAdmin,
        label: messages['claimsAdmin.title'],
        'data-testid': 'claimsAdminPage-btn',
      });
    }

    if (isTenantUserManagement) {
      items.push({
        key: RouteDefs.TenantUsers,
        label: messages['tenants.usersTitle'],
        'data-testid': 'tenantUsers-btn',
      });
    }

    items.push({
      key: RouteDefs.Tasks,
      label: messages['workers.tasks.title'],
      'data-testid': 'tasksPage-btn',
    });

    items.push({
      key: RouteDefs.Feedback,
      label: messages['feedback.title'],
      'data-testid': 'feedbackPage-btn',
    });

    items.push({
      key: 'logout',
      label: messages['main.logout'],
      'data-testid': 'logout-btn',
    });

    return items;
  }, [roles, messages]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topPanel}>
        {totalTimeOnPlatform && isOnTasksPage && (
          <div className={styles.timeSpent}>
            {intl.formatMessage(
              { id: 'workers.tasks.timeOnPlatform.title' },
              { timeOnPlatform: totalTimeOnPlatform }
            )}
          </div>
        )}
        <div className={styles.levelleft}>
          <div className={styles.menu}>
            <Menu
              onClick={onMenuItemClick}
              buttonClassName={styles.menuButton}
              menuItems={menuItems}
              aria-label="menu"
              data-testid="main-menu"
            />
          </div>
          <PagePartOutlet part="breadcrumbs" />
          <PagePartOutlet part="topPanelExtra" />
        </div>
        <div className={styles.levelright}>
          <PagePartOutlet part="topPanelActions" />
        </div>
      </div>

      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default MainContainer;
