import type { ComponentType } from 'react';
import baseLoadable, {
  DefaultImportedComponent,
  LoadableComponent,
  OptionsWithoutResolver,
} from '@loadable/component';
import type {
  PropsOf,
  NoArgsFunctionComponent,
  Empty,
} from '@owl-frontend/components';
import type { UnionToIntersection, ValueOf } from '@owl-lib/type-util';
import type { PagePartsConfig } from './page-parts';

// MARK: Simple typing
type MaybeDefaultImport<T> = T | { default: T };
type DefaultAnyComponent = MaybeDefaultImport<ComponentType<any>>;
type AnyComponentType = NoArgsFunctionComponent | React.ComponentType<any>;

export type LoadablePassthroughOptions = Pick<
  OptionsWithoutResolver<any>,
  'fallback'
>;
type LoadablePagePartsConfig = {
  [Part in keyof PagePartsConfig]: boolean | LoadablePassthroughOptions;
};
/** Extends the options from @loadable/component */
export interface LoadableOptions extends LoadablePassthroughOptions {
  parts?: LoadablePagePartsConfig;
}

export type PagePartsModule = {
  [Part in keyof PagePartsConfig]?: React.ComponentType<any>;
};
export type PagePartsLoadableComponents<Props> = {
  [Part in keyof PagePartsConfig]?: LoadableComponent<Props>;
};
export type LoadableComponentWithPageParts<Props> = LoadableComponent<Props> &
  PagePartsLoadableComponents<Props>;

// MARK: Strict typing
// Ensures that loadable will know about the exposed parts
export type StrictLoadableParts<ComponentModule> = UnionToIntersection<
  ValueOf<StrictLoadablePartsInner<ComponentModule>>
>;
type StrictLoadablePartsInner<ComponentModule> = {
  [K in keyof PagePartsConfig]-?: ComponentModule extends {
    [_ in K]: AnyComponentType;
  }
    ? { [_ in K]-?: true | LoadablePassthroughOptions }
    : { [_ in K]?: false };
};
type StrictLoadableOptionsInner<Parts> = Partial<Parts> extends Parts
  ? { parts?: Parts }
  : { parts: Parts };
type StrictLoadableOptions<ComponentModule> = StrictLoadableOptionsInner<
  StrictLoadableParts<ComponentModule>
>;
type StrictLoadableRestInner<Options> = Partial<Options> extends Options
  ? [options?: Options]
  : [options: Options];
type StrictLoadableRest<ComponentModule> = StrictLoadableRestInner<
  StrictLoadableOptions<ComponentModule>
>;
type StrictLoadableComponentWithPagePartsInner<ComponentModule> = {
  [K in keyof PagePartsConfig]-?: ComponentModule extends {
    [_ in K]: React.ComponentType<any>;
  }
    ? { [_ in K]-?: LoadableComponent<PropsOf<ComponentModule[K]>> }
    : Empty;
};
export type StrictLoadableComponentWithPageParts<
  ComponentModule extends DefaultImportedComponent<any>
> = LoadableComponent<PropsOf<ComponentModule['default']>> &
  UnionToIntersection<
    ValueOf<StrictLoadableComponentWithPagePartsInner<ComponentModule>>
  >;

// MARK: Implementation
/**
 * Wrap components with this component to enable points for code splitting.
 * See the docs for
 * [@loadable/component](https://loadable-components.com/docs/api-loadable-component)
 *
 * NOTE: this _must_ be named `loadable` for
 * [the babel plugin](https://loadable-components.com/docs/babel-plugin/#loadable-detection)
 * to work (only needed for SSR, but it's good to prepare for that.).
 * Experiment using https://astexplorer.net/#/gist/744a0e97a946e465637222ffda74ea7b/latest
 *
 * @see {@link ../components/Routing.tsx Routing}
 */
function loadable(
  loadFn: () => Promise<NoArgsFunctionComponent>
): LoadableComponent<Empty>;
function loadable<P>(
  loadFn: () => Promise<React.ComponentType<P>>
): LoadableComponent<P>;
function loadable<ComponentModule extends DefaultImportedComponent<any>>(
  loadFn: () => Promise<ComponentModule>,
  ...rest: StrictLoadableRest<ComponentModule>
): StrictLoadableComponentWithPageParts<ComponentModule>;
function loadable(
  loadFn: () => Promise<DefaultAnyComponent & PagePartsModule>,
  options: LoadableOptions = {}
) {
  const { parts = {}, ...rest } = options;
  type LCWPP = LoadableComponentWithPageParts<any>;
  const Loadable: LCWPP = baseLoadable(loadFn, rest);
  if (parts.breadcrumbs) {
    Loadable.breadcrumbs = baseLoadable(loadFn, {
      resolveComponent: (mod) => mod.breadcrumbs!,
      ...(typeof parts.breadcrumbs === 'boolean' ? {} : parts.breadcrumbs),
    });
  }
  if (parts.topPanelExtra) {
    Loadable.topPanelExtra = baseLoadable(loadFn, {
      resolveComponent: (mod) => mod.topPanelExtra!,
      ...(typeof parts.topPanelExtra === 'boolean' ? {} : parts.topPanelExtra),
    });
  }
  if (parts.topPanelActions) {
    Loadable.topPanelActions = baseLoadable(loadFn, {
      resolveComponent: (mod) => mod.topPanelActions!,
      ...(typeof parts.topPanelActions === 'boolean'
        ? {}
        : parts.topPanelActions),
    });
  }
  return Loadable;
}

export default loadable;
