import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ExportOutlined,
  LeftOutlined,
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import { Document, Page, pdfjs } from 'react-pdf';
import { Space, Spin } from 'antd';
import type { MatchAnswer } from '@owl-frontend/api-client';
import { Button, useEffectWithPrev } from '@owl-frontend/components';
import { Locale } from '../../../../../../context/AppContainer/AppContainer';
import { useResize } from '../../DocumentComponentHooks';
import styles from './PDFDocumentViewer.module.scss';

// Needed for the react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import 'react-pdf/dist/esm/Page/TextLayer.css';

type Area = { left: number; top: number; width: number; height: number };

export interface Props {
  documentPresignedUrl?: string;
  initialPage?: number;
  onChangePage?(number: number): void;
  documentMatches?: MatchAnswer[];
  highlight?: [number, number, number, number];
  documentUrl?: string;
}

const areaToKey = (area: Area) => {
  const { top, left, width, height } = area;
  return `${top}-${left}-${width}-${height}`;
};

const ZOOM_SCALE = 0.25;
const PAGE_OFFSET = 1;
const PADDING = 16;
const PDFDocumentViewer: React.VFC<Props> = ({
  documentPresignedUrl,
  initialPage = 1,
  onChangePage,
  documentMatches = [],
  highlight,
  documentUrl,
}) => {
  const [scale, setScale] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(initialPage);
  const [renderedPageNumber, setRenderedPageNumber] = useState<number>();
  const [originalPageWidth, setOriginalPageWidth] = useState<number>();
  const { messages } = useContext(Locale);
  const documentContainerRef = useRef<HTMLDivElement>(null);
  const { width: pageWidth } = useResize(documentContainerRef);

  const changeScale = (offset: number) => {
    setScale((prevScale) => prevScale + offset);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf._pdfInfo.numPages);

    if (pageNumber > pdf._pdfInfo.numPages) {
      setPageNumber(1);
      return;
    }

    setPageNumber(pageNumber);
  };

  const isLoading = renderedPageNumber !== pageNumber;

  useEffectWithPrev(
    (prevInitialPage) => {
      if (!initialPage || prevInitialPage === initialPage) {
        return;
      }

      setPageNumber(initialPage);
    },
    [initialPage, setPageNumber, numPages]
  );

  useEffectWithPrev(
    (prevPageNumber) => {
      if (!onChangePage || !pageNumber || prevPageNumber === pageNumber) {
        return;
      }

      onChangePage(pageNumber);
    },
    [pageNumber, onChangePage]
  );

  const terms = useMemo(() => {
    return documentMatches
      .map((d) => {
        if (!d.snippet) {
          return null;
        }

        const emphasizedText = d.snippet.match('<em>(.*?)</em>');

        if (!emphasizedText) {
          return null;
        }

        return emphasizedText[1].trim();
      })
      .filter((d) => !!d);
  }, [documentMatches]);

  const highlightScale = useMemo(() => {
    if (!pageWidth || !scale || !originalPageWidth) {
      return 0;
    }

    return ((pageWidth - PADDING) * scale) / originalPageWidth;
  }, [originalPageWidth, pageWidth, scale]);

  const areas = useMemo<Area[]>(
    () =>
      documentMatches
        .map((d) => {
          if (!d.bb || d.page !== pageNumber) {
            return null;
          }

          const [left, top, width, height] = d.bb.map(
            (i) => i * highlightScale
          );
          return { left, top, width, height };
        })
        .filter((d): d is Area => d !== null),
    [documentMatches, pageNumber, highlightScale]
  );

  const onPageRenderSuccess = useCallback(
    (data) => {
      setOriginalPageWidth(data.originalWidth);
      setRenderedPageNumber(pageNumber);
    },
    [pageNumber, setRenderedPageNumber]
  );

  return (
    <div className={styles.documentContainer}>
      <div ref={documentContainerRef} className={styles.documentWrapper}>
        <div>
          <Document
            file={documentPresignedUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <Spin spinning={true}>
                <div className={styles.documentLoading} />
              </Spin>
            }
          >
            {isLoading && renderedPageNumber ? (
              <Page
                key={renderedPageNumber}
                pageNumber={renderedPageNumber}
                renderAnnotationLayer={false}
                scale={scale}
                width={pageWidth - PADDING}
              />
            ) : null}
            <Page
              className={clsx(isLoading && renderedPageNumber && styles.hidden)}
              key={pageNumber}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              scale={scale}
              width={pageWidth - PADDING}
              onRenderSuccess={onPageRenderSuccess}
              loading={
                <Spin spinning={true}>
                  <div className={styles.documentLoading} />
                </Spin>
              }
              customTextRenderer={({ str }) => {
                if (str.trim().length === 0) {
                  return '';
                }

                const result: string[] = [];
                for (const term of terms) {
                  if (!term) {
                    continue;
                  }

                  if (str.indexOf(term) > -1) {
                    result.push(
                      str.replace(
                        term,
                        `<span style="background: yellow; opacity: 0.35">${term}</span>`
                      )
                    );
                  }
                }

                return result.join('');
              }}
            >
              {areas.map((area) => (
                <div
                  key={areaToKey(area)}
                  className={styles.pdfSectionMatch}
                  style={area}
                />
              ))}
              {highlight && (
                <div
                  className={styles.pdfHighlight}
                  style={{
                    left: highlight?.[0] * highlightScale,
                    top: highlight?.[1] * highlightScale,
                    width: highlight?.[2] * highlightScale,
                    height: highlight?.[3] * highlightScale,
                  }}
                />
              )}
            </Page>
          </Document>
        </div>
      </div>
      <div className={styles.pdfUtil}>
        <div>
          <Space align="start">
            <Button
              size="small"
              icon={<MinusOutlined />}
              disabled={scale <= 0.5}
              onClick={() => {
                changeScale(-ZOOM_SCALE);
              }}
            />
            <Button
              size="small"
              icon={<PlusOutlined />}
              disabled={scale >= 3}
              onClick={() => {
                changeScale(ZOOM_SCALE);
              }}
            />
            {documentUrl && (
              <Button
                className={styles.openInNewTab}
                size="small"
                icon={<ExportOutlined />}
                href={documentUrl}
                target="_blank"
              >
                {messages['claims.documents.view.pdfDocument.openInNewTab']}
              </Button>
            )}
          </Space>
        </div>
        <div>
          <Space>
            <Button
              size="small"
              icon={<LeftOutlined />}
              disabled={pageNumber <= 1}
              onClick={() => {
                changePage(-PAGE_OFFSET);
              }}
            />
            <Button size="small">
              {pageNumber ?? (numPages ? 1 : '--')} / {numPages ?? '--'}
            </Button>
            <Button
              size="small"
              icon={<RightOutlined />}
              disabled={pageNumber >= numPages}
              onClick={() => {
                changePage(PAGE_OFFSET);
              }}
            />
          </Space>
        </div>
      </div>
    </div>
  );
};

export default PDFDocumentViewer;
