import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseURL } from '@owl-frontend/api-client';
import { DialogLanding, useEffectWithPrev } from '@owl-frontend/components';
import { ErrorCode } from '@owl-lib/util';

import { RouteDefs } from '../../components/Routing/routes';
import Routing from '../../components/Routing/Routing';
import { actions as sessionSliceActions } from '../../data/session/logic';
import { actions as falconSettingsSliceActions } from '../../data/settings/logic';
import type { StoreState } from '../../data/store';

/**
 * AppContainer
 * ============
 * Initial session and current User fetch contained here
 * - redirects to Cognito Login Flow when no valid session key established
 * - gets current User's Role from `sessionSliceActions.current` dispatch call
 */

export const Locale = React.createContext({
  messages: {},
  locale: 'en-ca',
});

export const SessionContext = React.createContext<
  Omit<StoreState['session'], 'actions'>
>({});

const AppContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { messages, locale } = useSelector(
    (s: StoreState) => s['localization']
  );
  const sessionActions = useSelector((s: StoreState) => s['session'].actions);
  const currentUser = useSelector((s) => s['session'].current);
  const { actions, ...session } = useSelector((s: StoreState) => s['session']);

  React.useEffect(() => {
    if (!currentUser || location.pathname !== '/') {
      return;
    }

    if (currentUser?.roles.indexOf('cs') > -1) {
      navigate(RouteDefs.Claims);
      return;
    }

    if (currentUser?.roles.indexOf('admin') > -1) {
      navigate(RouteDefs.Tenants);
      return;
    }

    navigate(RouteDefs.Tasks);
  }, [currentUser, location, navigate]);

  React.useEffect(() => {
    dispatch(sessionSliceActions.refresh());
    dispatch(falconSettingsSliceActions.fetch());
  }, []);

  useEffectWithPrev(
    (prevAction) => {
      if (prevAction?.status !== 'pending') {
        return;
      }

      const { error } = sessionActions.refresh;

      if (!error) {
        dispatch(sessionSliceActions.current());
        return;
      }

      if (error.code === ErrorCode.ForbiddenUnauthenticated) {
        window.location.href = `${baseURL}/api/login`;
      }
    },
    [sessionActions.refresh]
  );

  /**
   * MARK: RENDER
   */

  if (!currentUser) {
    return null;
  }

  return (
    <Locale.Provider
      value={{
        messages,
        locale,
      }}
    >
      <SessionContext.Provider value={session}>
        <Routing />
        <DialogLanding />
      </SessionContext.Provider>
    </Locale.Provider>
  );
};
export default AppContainer;
