import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import type { Tenant } from '@owl-frontend/api-client/interface';
import { useEffectWithPrev } from '@owl-frontend/components';
import type { StoreState } from '../../data/store';
import type { InvestigationData } from './data/interface';
import { actions as tenantsActions } from './data/logic';

interface TenantsState {
  tenants: Tenant[];
  lastCursor: string | null;
  loading: boolean;
}
export function useTenants(): [
  TenantsState,
  {
    clearState(): void;
    fetchTenants(data?: { cursor?: string }): void;
  }
] {
  const dispatch = useDispatch();
  const { items: tenants, lastCursor } = useSelector((s) => s['tenants']);
  const tenantsListAction = useSelector(
    (s: StoreState) => s['tenants'].actions.tenantsList
  );

  const handler = React.useMemo(
    () => ({
      clearState: () => {
        dispatch(tenantsActions.clearItems());
      },
      fetchTenants: ({
        cursor,
      }: {
        cursor?: string;
      } = {}) => {
        dispatch(
          tenantsActions.tenantsList({
            cursor,
          })
        );
      },
    }),
    []
  );

  React.useEffect(() => {
    dispatch(tenantsActions.clearItems());
    dispatch(tenantsActions.tenantsList({}));
  }, []);

  return [
    {
      tenants,
      lastCursor,
      loading: tenantsListAction.status === 'pending',
    },
    handler,
  ];
}

interface TenantsPriorityConfigState {
  visible: boolean;
}

interface TenantsPriorityConfigHandler {
  openConfig(): void;
  onCancel(): void;
  onSubmit(
    tenants: {
      tenantId: string;
      priority: number;
      executionQuantity: number;
      maxReviewLimit: number;
      enforceLimit: boolean;
      reviewRollover: boolean;
    }[]
  ): void;
}

export function useTenantsPriorityConfig(): [
  TenantsPriorityConfigState,
  TenantsPriorityConfigHandler
] {
  const dispatch = useDispatch();
  const updateTenantPriorities = useSelector(
    (s: StoreState) => s['tenants'].actions.updateTenantPriorities
  );
  const [visible, setVisible] = React.useState(false);

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      dispatch(tenantsActions.clearItems());
      dispatch(tenantsActions.tenantsList({}));

      if (updateTenantPriorities.status === 'rejected') {
        Modal.error({
          title: 'Error',
          content: 'Failed to update priorities... Please try again.',
        });
      }
    },
    [updateTenantPriorities.status]
  );

  const handler = React.useMemo<TenantsPriorityConfigHandler>(
    () => ({
      openConfig: () => {
        setVisible(true);
      },
      onSubmit: (
        tenants: {
          tenantId: string;
          priority: number;
          executionQuantity: number;
          maxReviewLimit: number;
          enforceLimit: boolean;
          reviewRollover: boolean;
        }[]
      ) => {
        dispatch(tenantsActions.updateTenantPriorities({ tenants }));
        setVisible(false);
      },
      onCancel: () => {
        setVisible(false);
      },
    }),
    []
  );

  return [{ visible }, handler];
}

interface TenantInvestigationsState {
  investigationItems: InvestigationData[];
  investigationItemsLoading: boolean;
}
export function useTenantInvestigations(): [
  TenantInvestigationsState,
  { fetchInvestigations(): void }
] {
  const dispatch = useDispatch();
  const investigationItems = useSelector(
    (s) => s['tenants'].investigationItems
  );
  const listInvestigationItems = useSelector(
    (s) => s['tenants'].actions.investigationsList
  );
  const handler = React.useMemo(
    () => ({
      fetchInvestigations: () => {
        dispatch(tenantsActions.investigationsList());
      },
    }),
    []
  );

  React.useEffect(() => {
    dispatch(tenantsActions.investigationsList());
  }, []);

  return [
    {
      investigationItems,
      investigationItemsLoading: listInvestigationItems.status === 'pending',
    },
    handler,
  ];
}
