import type { Fowl } from '@owl-frontend/api-client/interface';
import type { TaskQueuePausedState } from '@owl-frontend/api-client/src/fowl/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export interface TopologiesState {
  topologies: Fowl.Topology[];
  defaults: Fowl.DefaultVersions;
  topologyTaskQueues: { [eventSourceArn: string]: TaskQueuePausedState };
  pausedTopologyTaskQueues: string[];
  actions: {
    listTopologies: AsyncActionState;
    updateTaskQueuePauseState: AsyncActionState;
    getTaskQueuesMappingState: AsyncActionState;
  };
}

const initialState: TopologiesState = {
  topologies: [],
  defaults: {},
  topologyTaskQueues: {},
  pausedTopologyTaskQueues: [],
  actions: {
    listTopologies: { status: 'uninitialized' },
    updateTaskQueuePauseState: { status: 'uninitialized' },
    getTaskQueuesMappingState: { status: 'uninitialized' },
  },
};

const fowlSlice = setupSlice('fowl', initialState);
export default fowlSlice;
